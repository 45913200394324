import React from "react";
import axios from 'axios';
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "../../styles/layout.css"
import FacebookLoginWithButton from 'react-facebook-login';
import { CongratulationsScreen } from "../congrats-screen";
import { HeaderBar } from '../header.jsx'
import { Footer } from '../footer.jsx'


const LoginButton = ({facebookResponse}) => (
<FacebookLoginWithButton
    appId="351229135644330"
    // autoLoad
    fields="name,email,picture"
    // onClick={componentClicked}
    callback={facebookResponse}
    icon="fa-facebook"/>
)


//   const UserScreen = ({user}) => (
//     <>
//       <h1>Welcome {user.name}!</h1>
//       <p>{ user.email }</p>
//       <img src={user.picture.data.url} height={user.picture.height} width={user.picture.width} alt="avatar"/>
//     </>
//   )
  
  class MainPage extends React.Component {
    state = {user:false}
    facebookResponse = (response) => { console.log( response ); this.setState( {...this.state, user: response } ); axios.post('https://cors-anywhere.herokuapp.com/https://hooks.zapier.com/hooks/catch/6992009/oggxkpj', { user: response }, { headers: {"Access-Control-Allow-Origin": "*"} } ) }    
    render() {
      return (
        <>
            <Helmet>
                <title>Pique - The Dating App For People You Already Know</title>
                <meta
                    name="description"
                    content="Life is too short to date strangers. Pique makes it easy for your to date your friends and coworkers."
                />
            </Helmet>
            <HeaderBar/>
            <main class="wrapper font-futura-pt">
                    <div style={{padding: '64px 16px', minHeight: '70vh' }}>
                        { this.state.user ? <CongratulationsScreen user={this.state.user} /> :
                        <div>
                            <h1 class="font-orpheus">Connect Your Facebook Account</h1>
                            <p>
                                The final step before we can start matching you with your friends.
                                <br/><br/>
                                We'll never post on your behalf. No one will know you're on Pique.
                            </p>
                            <br/>
                            <LoginButton facebookResponse={this.facebookResponse}/>
                        </div>
                        }
                    </div>
            </main>
            <Footer/>
        </>
      )
    }
}

export default MainPage