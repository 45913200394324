import { createMuiTheme } from "@material-ui/core/styles";

// Material-ui theme
// https://next.material-ui.com/customization/default-theme/#default-theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
    error: {
      main: "#FF4500",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#000000",
    },
  },
});

export default theme;
