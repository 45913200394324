import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
    fontsize: 16px;
    color: black;
    background: #f6f6f6 ;
    min-height: 30vh
`;

const FooterTextDiv = styled.div`
    margin: auto;
    max-width: 1200px;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
`;


export const Footer = () => {
    return (
      <div class="full-bleed font-futura-pt">
        <FooterContainer>
            <FooterTextDiv>
                <div>
                    <p><strong>Built with 😈 in Toronto</strong></p>
                    <p>Send us hate mail: hey@trypique.com</p>
                    <p>Pick us up for dinner: 211 Manning Avenue, Toronto, M6J 2K8</p>
                </div>
            </FooterTextDiv>
        </FooterContainer>
      </div>
    );
  };