import React from "react";
// import namemark from "../images/soar-white-namemark.png";
// import blacknamemark from "../images/soar-black-namemark.png";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavContainer = styled.div`
    font-family: "Orpheus";
    font-size: 24px;
    color: white;
    top: 0;
    position: absolute;
    zindex: 2;
    width: 100%;
    background: white;
    height: 64px
`;

const NavTextDiv = styled.div`
    height: 64px;
    margin: auto;
    max-width: 1200px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
`;

const HeaderLink = styled(Link)`
    text-decoration: none;
    color: black;
    padding: 16px;
`;

export const HeaderBar = () => {
    return (
      <NavContainer style={{ zIndex: 100 }}>
        <NavTextDiv>
        <div style={{ lineHeight: "64px" }}>
            <HeaderLink to="/">Pique</HeaderLink>
        </div>
        <div style={{ lineHeight: "64px" }}>
            <HeaderLink to="/">Join the wait list</HeaderLink>
        </div>
        </NavTextDiv>
      </NavContainer>
    );
  };