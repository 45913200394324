import React from 'react';
import './App.css';

import theme from "./styles/theme";
import { ThemeProvider } from "@material-ui/styles"

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import MainPage from "./components/routes/main-page";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={MainPage} />
      </Switch>
    </Router>
  );
};

export default App;
