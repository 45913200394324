import React from 'react';

export const CongratulationsScreen = ({user}) => (
        <>
            <h1>You're all set, {user.name}!</h1>
            <p>
                You're position <strong>{Math.round( 130 + Math.random() * (  Date.now() - 1602632619626 ) / 86400 ) }</strong> on our waitlist.
                <br/><br/>
                To move up on the list, <strong><a style={{color: "#00516A"}}href="https://form.typeform.com/to/l5Sqa54J">share this link with friends</a></strong>.
                <br/><br/>
                As a bonus, you'll both get a free year of Pique Premium.
            </p>

            {/* <img src={user.picture.data.url} height={user.picture.height} width={user.picture.width} alt="avatar"/> */}
        </>
      )

// export default CongratulationsScreen;